import React from 'react'
import Layout from '../components/Layout'
import * as styles  from '../styles/project-details.module.css'
import { graphql } from 'gatsby'
import LeftNavBarCSharpTutorial from '../components/tutorials/csharp/LeftNavBarCSharpTutorial'
import { Row, Col}  from 'react-bootstrap'
import PreviousNext from '../components/Previous_Next'

import Seo from '../components/SEO'

export default function AspnetCourseDetails({data, pageContext }) {
    const { html } = data.markdownRemark
    const { title, modified} = data.markdownRemark.frontmatter
  
    const { next, previous } = pageContext

      return (
        <Layout>
            <Seo
              title={"C# " + title}
              description="C# for beginners"
              keywords="C# for beginners, Learn C# Programming"
            />
              <Row>
                  <Col xs={12} md={3} className="d-none d-lg-block">
                    <LeftNavBarCSharpTutorial></LeftNavBarCSharpTutorial>
                  </Col>
                  <Col xs={12} md={9}>
                  <div className={styles.details}>
                      <h1>{"C# "+ title}</h1>
                      <small>Last modified: {modified}</small>
                      <PreviousNext baseURL={"/"} previous={previous} next={next}></PreviousNext>               
                      <div className={styles.html} dangerouslySetInnerHTML={{ __html: html }} />
                      <PreviousNext baseURL={"/"} previous={previous} next={next}></PreviousNext>               
                  </div>
                  </Col>
              </Row>
          </Layout>
      )
}

export const query = graphql`
  query cSharpTutorials($slug: String) {
    markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        slug
        sequence
        modified(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
